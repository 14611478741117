import {
  Button,
  Grid,
} from '@mui/material'
import React from 'react'
import '../styles/style.css'

const Footer = () =>
  <div className='footer'>
    <div className='footerHome'>
      <Grid container>
        <Grid item xs={5.9} sx={{
          justifyContent: 'right',
        }}>
          <Button
            rel='noopener noreferrer'
            title='Modulara>'
            alt='Modulara>'
            href='./'
            disableRipple={true}
            sx={{
              textTransform: 'none',
              '&:hover': { background: 'none' }
            }}
          >
            © 2023 Modulara
          </Button>
        </Grid>
        <Grid item xs={0.2} sx={{
          fontWeight: '400',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          |
        </Grid>
        <Grid item xs={5.9} sx={{
          justifyContent: 'left',
        }}>
          <Button
            rel='noopener noreferrer'
            title='Email Modulara'
            alt='Email Modulara'
            href='mailto:info@modulara.com'
            disableRipple={true}
            sx={{
              justifyContent: 'left',
              textTransform: 'none',
              '&:hover': { background: 'none' }
            }}
          >
            info@modulara.com
          </Button>
        </Grid>
      </Grid>
    </div>
  </div>

export default Footer