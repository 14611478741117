import React from 'react'
import ReactDOM from 'react-dom'
import LandingContainer from "./containers/LandingContainer"
import AboutUs from "./components/AboutUs"
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import './styles/style.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingContainer isMainPage={false} />}/>
        <Route exact path="/about" element={<AboutUs isMainPage={true} hideModels={true} />}/>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
