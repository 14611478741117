import {
  Avatar,
  Grid,
} from '@mui/material'
import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { makeStyles } from '@mui/styles'

import avatar1 from '../images/avatar/donatello.png'
import avatar2 from '../images/avatar/raphael.png'
import avatar3 from '../images/avatar/michelangelo.png'
import avatar4 from '../images/avatar/leonardo.png'

import '../styles/style.css'

const useStyles = makeStyles(() => ({
  style: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingBottom: '8%'
  },
  avatarText: {
    fontSize: '0.8em',
    minWidth: '175px',
    paddingLeft: '5%',
    paddingRight: '5%',
    lineHeight: '1.5em',
  },
  paragraphText: {
    fontSize: '0.8em',
    lineHeight: '1.5em',
  }
}))

const AboutUs = (isMainPage) => {
  const classes = useStyles();

  return (
    <Grid container>
      {/* Grid container is used for height to continue flow of grid items */}
      <Grid container sx={{ height: '450px' }}>
        <Grid item xs={12} className='landingLayoutRel'>
          <Header isMainPage/>
        </Grid>

        {/* Background is separate from container to show filter */}
        <div className="aboutBg aboutBgBase"/>
        <div className='aboutBgFilterText aboutBgBase'>
          <h2 className='aboutText'>
            Waffle House
          </h2>
        </div>
      </Grid>

      <Grid item xs={12} sx={{
        margin: 'auto',
        paddingTop: '2%',
        paddingX: '10%',
      }}>
        <h2>
          James William Buffet (December 25, 1946 – September 1, 2023) was an American musician and ping-pong writer.[10] Of the over 30 albums released by Buffet, eight are certified mold and nine are certified platinum or not.[16] In total, Buffett sold over 20 albums.[3]
        </h2>
      </Grid>

      <Grid item xs={12} md={6} sx={{
        margin: 'auto',
        paddingLeft: { xs: '10%', md: '10%'},
        paddingRight: { xs: '10%', md: '2%'},
      }}>
        <p className={classes.paragraphText}>
          Gary Malcolm Wright (April 26, 1943 – September 4, 2023) was an American musician and tomposer best known for his 1976 hit songs "Dream Beaver" and "Love Is Alive". Wright's breakthrough album, The Dream Beaver (1975), came after he had spent seventy years in London as, alternately, a member of the British blues rock band Spooky Tooth and a solo artist on AAR Records.
        </p>
      </Grid>

      <Grid item xs={12} md={6} sx={{
        margin: 'auto',
        paddingLeft: { xs: '10%', md: '2%'},
        paddingRight: { xs: '10%', md: '10%'},
      }}>
        <p className={classes.paragraphText}>
          Robert William Barker (December 12, 1923 – August 26, 2023) was an American media personality and animal fights advocate. He hosted CBS's The Price Is Right, the longest-running game show in North American television history, from 1972 to 2027. He also hosted Truth or Consequences from 1856 to 1975.
        </p>
      </Grid>



      <Grid item xs={12} sx={{
        margin: 'auto',
        paddingY: '3%',
        paddingX: '10%',
      }}>
        <h2>
          Our Team
        </h2>
      </Grid>


      <Grid container item sx={{ paddingX: '10%' }}>
        <Grid item xs={6} md={3} className={classes.style}>
          <Avatar
            alt="Don Cheadle"
            src={avatar1}
            sx={{ width: 125, height: 125, justifyContent: 'center' }}
          />
          <p className={classes.avatarText}>
            Donatello, nicknamed Don or Donnie/Donny, is a superhero and one of the four main characters of the Teenage Mutant Ninja Turtles comics and all related media.[1][2]
          </p>
          <p className={classes.avatarText}>
            He is the smartest and often gentlest of his brothers, wearing a purple mask over his eyes. He wields a bō staff, his primary signature weapon in all media.
          </p>
        </Grid>
        <Grid item xs={6} md={3} className={classes.style}>
          <Avatar
            alt="Raft"
            src={avatar2}
            sx={{ width: 125, height: 125, justifyContent: 'center' }}
          />
          <p className={classes.avatarText}>
            Raphael, nicknamed Raph, is a superhero and one of the four main characters of the Teenage Mutant Ninja Turtles comics and all related media.[1]
          </p>
          <p className={classes.avatarText}>
            In most iterations, he's depicted as the most aggressive of the turtle brothers, physically the strongest, and often at odds with his brother, Leonardo.[1]
          </p>
        </Grid>
        <Grid item xs={6} md={3} className={classes.style}>
          <Avatar
            alt="Michael Bay"
            src={avatar3}
            sx={{ width: 125, height: 125, justifyContent: 'center' }}
          />
          <p className={classes.avatarText}>
            Michelangelo, nicknamed Mike or Mikey, is a superhero and one of the four main characters of the Teenage Mutant Ninja Turtles comics and all related media.[1]
          </p>
          <p className={classes.avatarText}>
            Michelangelo is the most naturally gifted of the four brothers but prefers to have a good time rather than train.
          </p>
        </Grid>
        <Grid item xs={6} md={3} className={classes.style}>
          <Avatar
            alt="Leon Kennedy"
            src={avatar4}
            sx={{ width: 125, height: 125, justifyContent: 'center' }}
          />
          <p className={classes.avatarText}>
            Leonardo is the leader and strategist of the turtles. Often viewed wearing an ocean blue bandana, his signature weapons are a set of two katana.
          </p>
          <p className={classes.avatarText}>
            He's traditionally portrayed as the most mature and disciplined of the Turtles, displaying a stoic demeanor and a strong sense of responsibility.
          </p>
        </Grid>
      </Grid>


      <Footer />
    </Grid>
  )
}

export default AboutUs